import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Header from './Header';
import Porfolio from './Porfolio';
let store = null;
var self = null;
@inject("appStore")
@observer
class AboutMe extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  ">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Intro
                                </h2>
                                <div class="o-content__body  o-section__description">
                                    What I am all about.
                                </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  ">
                            
                            <div class="o-content">
                                <div class="c-intro">
                                    <div class="o-content__body">
                                        <p>
                                        Hi, myself Shoubhik Banerjee, working as an IT Expert at Volkswagen Group Technology Solutions India Private Limited (VWITS). since March 2023.
                                            {/* <a href="#" target="_blank">pretium</a>, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti. Nulla vitae mauris non felis mollis faucibus. */}
                                        </p>
                                        <p>
                                        I am an experienced IT expert with a strong background in cloud computing, machine learning, and software development. Currently, I am working at Volkswagen Group Technology Solutions India Private Limited (VWITS), where I focus on building robust AWS infrastructure solutions like AWS Landing Zone, organization structures, service control policies (SCPs), and chatbots using AWS-native services like AWS Bedrock.
                                        </p>
                                        <p>
With previous experience in developing products based on AWS services (Lambda, Step Functions, SQS, SNS, DynamoDB) and creating intelligent NLP solutions, I have a deep understanding of both cloud architecture and artificial intelligence technologies. I specialize in building end-to-end solutions using Node.js, React, Python, and various cloud services, enhancing business automation, and delivering advanced, scalable solutions.
                                        </p>
                                        <p>
My passion lies in combining cloud architecture with AI capabilities, helping businesses streamline operations and deliver innovative customer-facing solutions. I am constantly evolving my skills to stay ahead of the curve in cloud technologies and machine learning.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

        );
    }
}

export default withRouter(AboutMe);