import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Header from './Header';
import Porfolio from './Porfolio';
import AboutMe from './AboutMe';
import RightPanelOptions from './RightPanelOptions';
import Experience from './Experience';
import CoverProfile from './CoverProfile';
import Education from './Education';
import Contact from './Contact';
let store = null;
var self = null;
@inject("appStore")
@observer
class Home extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    return (

      <div>
        <Header />
        <AboutMe />
        <Porfolio />
        <Experience />
        <Education />
        <Contact/>
      </div>


    );
  }
}

export default withRouter(Home);