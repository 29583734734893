import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AppHelperStore from './AppStoreHandler/appHelperStore';
import { loadCustom } from './CustomJs/custom_js'

import Home from './Pages/Home';

const appStore = new AppHelperStore();
var intViewportHeight = window.innerHeight;
class App extends React.Component {

  componentDidMount = () => {
    // loadCustom();
  }

  render() {
    return (
      <div>
        <Provider appStore={appStore}>
          <Router>
            <Switch>
            {/* <Route exact path="/"
                render={() =>
                  <Redirect to="/home" />
                } /> */}
               {/* <Route path="/home" component={Home} /> */}
               <Route path="/" component={Home} />
            </Switch>
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
