import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import DownloadAndShare from './DownloadAndShare';
const $ = window.$;
let store = null;
var self = null;
@inject("appStore")
@observer
class Header extends React.Component {

	constructor(props) {
		super(props);
	}

	handleMenuClicked = (e) => {

		try {

		} catch {

		}

	}


	render() {
		return (
			<section class="o-section o-section--header  t-section  t-section--header">
				<div class="c-header">

					<div class="o-section__header-bg  t-section__header"></div>
					<div class="o-section__content-bg  t-section__content"></div>

					<div class="o-container">
						<div class="o-section__container">

							<header class="o-section__header  c-header__header  t-section__header">
								<div class="c-header__inner-header">

									<div class="c-header__avatar">
										<div class="a-header  c-avatar">
											<img class="c-avatar__img" src="assets/images/header/avatar.jpg" alt="" />
										</div>
									</div>

								</div>
							</header>

							<div class="o-section__content  c-header__content  t-section__content">
								<div class="c-header__inner-content">

									<div class="c-header__top">

										<div class="c-header__brand">

											<div class="c-brand">
												<h1 class="c-brand__title  t-title">
													<span class="c-brand__sizer">
														<span class="a-header  c-brand__first-word  t-title__first-word">
															Shoubhik
                                                        </span>
														<span class="a-header  c-brand__second-word  t-title__second-word">
															Banerjee
                                                        </span>
													</span>
												</h1>
												<h2 class="a-header  c-brand__sub-title  t-sub-title">
													<span class="c-brand__sizer">
														- A MINIMAL FULLSTACK DEVELOPER
                                                    </span>
												</h2>
											</div>

										</div>

										<ul class="c-header__social-buttons  c-social-buttons">
											<li class="a-header">
												<a href="https://wa.me/917061648322&text=Hello!" target="_blank" class="c-social-button  t-social-button">
													<i class="fa fa-whatsapp"></i>
												</a>
											</li>

											<li class="a-header">
												<a href="https://www.linkedin.com/in/shoubhik-banerjee-696838109/" target="_blank" class="c-social-button  t-social-button">
													<i class="fa fa-linkedin"></i>
												</a>
											</li>
											<li class="a-header">
												<a href="mailto:banerjee.shoubhik01@gmail.com" target="_blank" class="c-social-button  t-social-button">
													<i class="fa fa-envelope-o"></i>
												</a>
											</li>
										</ul>

									</div>

									



									<DownloadAndShare />

									<div class="c-header__contact">

										<hr class="a-header  c-header__contact-divider" />

										<div class="o-grid">

											<div class="o-grid__col-md-3  o-grid__col-sm-6">
												<div class="a-header  o-content">
													<div class="o-content__body">
														<h4>Location</h4>
														<address>
															Pune, India
                                                        </address>
													</div>
												</div>
											</div>

											<div class="o-grid__col-md-3  o-grid__col-sm-6">
												<div class="a-header  o-content">
													<div class="o-content__body">
														<h4>Phone</h4>
														<p>
															+91 - 7061648322
                                                        </p>
													</div>
												</div>
											</div>

											<div class="o-grid__col-md-3  o-grid__col-sm-6">
												<div class="a-header  o-content">
													<div class="o-content__body">
														<a href="#" target="_blank" class="t-link-container">
															<h4>Web</h4>
															<p>
																<span class="t-link-container__item--blended">
																	shoubhikbanerjee.com
                                                                </span>
															</p>
														</a>
													</div>
												</div>
											</div>

											<div class="o-grid__col-md-3  o-grid__col-sm-6">
												<div class="a-header  o-content">
													<div class="o-content__body">
														<a href="#" target="_blank" class="t-link-container">
															<h4>Email</h4>
															<p>
																<span class="t-link-container__item--blended">
																	banerjee.shoubhik01@gmail.com
                                                                </span>
															</p>
														</a>
													</div>
												</div>
											</div>

										</div>
									</div>

								</div>
							</div>

						</div>

					</div>

				</div>
			</section>

		);
	}
}

export default withRouter(Header);