import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import Header from './Header';
let store = null;
var self = null;
@inject("appStore")
@observer
class Portfolio extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  ">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Skills
                                </h2>
                                <div class="o-content__body  o-section__description">
                                    Progress bars, anyone?
                                </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  ">

                            <div class="o-grid">

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    60<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Chatbot Development & Frameworks:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="60"></div>
                                        </div>
                                        <ul>
                                            <li>
                                             End-to-end chatbot solutions (Dialogflow, Rasa, AWS Lex, AWS Bedrock, Botpress)
                                            </li>
                                            <li>
                                            Contextual Chatbots, Conversational AI Design
                                            </li>
                                            <li>
                                            Integration with Databases (PostgreSQL, DynamoDB) and APIs
                                            </li>
                                            <li>
                                            Real-time Messaging with WebSockets (Socket.io, AWS API Gateway)
                                            </li>
                                            <li>
                                            Building Multilingual Bots and Voice Assistants
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    50<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>AI & Machine Learning:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="50"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                Natural Language Processing (NLP)
                                            </li>
                                            <li>
                                                Text Classification, Summarization, Open-domain Question Answering
                                            </li>
                                            <li>
                                                Recommender Systems & Chatbots (Dialogflow, AWS Bedrock, LlamaIndex, Langchain, etc.)
                                            </li>
                                            <li>
                                                Transformers, LSTMs, Deep Learning Models
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    70<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Cloud Platforms</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="70"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                AWS (Lambda, CDK, Step Functions, SQS, SES, SNS, DynamoDB, S3, Cognito, Bedrock)
                                            </li>
                                            <li>
                                                Google Cloud Platform (GCP)
                                            </li>
                                            <li>
                                                AWS Landing Zone & Organization Structure Setup
                                            </li>
                                            <li>
                                                Service Control Policies (SCPs)
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    75<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Backend Development:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="75"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                Python (AWS Boto3, Flask, FastAPI)
                                            </li>
                                            <li>
                                                Node.js
                                            </li>
                                            <li>
                                                APIs Development
                                            </li>
                                            <li>
                                                RESTful Services & Microservices Architecture
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    80<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Frontend Development:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="80"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                React.js
                                            </li>
                                            <li>
                                                HTML5, CSS3, JavaScript
                                            </li>
                                            <li>
                                                Frontend Integration with APIs
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    70<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Databases & Storage:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="70"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                Aurora Serverless, PostgreSQL, DynamoDB, MongoDB, Firebase
                                            </li>
                                            <li>
                                                AWS S3, GCP Bucket
                                            </li>
                                            <li>
                                                Data Modeling and Query Optimization
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    65<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>DevOps & CI/CD:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="65"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                Infrastructure as Code (AWS CDK, NodeJS/Python)
                                            </li>
                                            <li>
                                                Git, Bitbucket, AWS CodeCommit, AWS CodePipeline
                                            </li>
                                            <li>
                                                Continuous Deployment & Monitoring
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="o-grid__col-sm-6">
                                    <div class="o-content">
                                        <div class="o-media  o-media--block">
                                            <div class="o-media__figure">
                                                <div class="c-number  t-primary-color">
                                                    80<small>%</small>
                                                </div>
                                            </div>
                                            <div class="o-media__body">
                                                <h3>Other Tools & Technologies:</h3>
                                            </div>
                                        </div>
                                        <div class="c-progress-bar  o-content__body  t-border-color-bg  u-mt-text">
                                            <div class="a-progress-bar  c-progress-bar__filler  t-primary-bg" data-percent="80"></div>
                                        </div>
                                        <ul>
                                            <li>
                                                AWS SDKs and AWS CLI (NodeJS/Python)
                                            </li>
                                            <li>
                                                Socket.io and AWS API gateway(Real-time WebSockets)
                                            </li>
                                            <li>
                                                Authentication & Authorization (AWS Cognito, Firebase)
                                            </li>
                                        </ul>
                                    </div>
                                </div>




                            </div>

                        </div>

                    </div>
                </div>

            </section>

        );
    }
}
export default withRouter(Portfolio);