import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import DownloadAndShare from './DownloadAndShare';
let store = null;
var self = null;
@inject("appStore")
@observer
class Contact extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  o-section--footer">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Contact
                                </h2>
                                <div class="o-content__body  o-section__description">
                                    Call me, maybe.
                                </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  ">

                            <div class="c-footer__contact">
                                <div class="o-grid">

                                    <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                        <div class="o-content">
                                            <div class="o-content__body">
                                                <h4>Location</h4>
                                                <address>
                                                Pune, India
                                                </address>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                        <div class="o-content">
                                            <div class="o-content__body">
                                                <h4>Phone</h4>
                                                <p>
                                                +91 - 7061648322
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                        <div class="o-content">
                                            <div class="o-content__body">
                                                <a href="#" target="_blank" class="t-link-container">
                                                    <h4>Web</h4>
                                                    <p>
                                                        <span class="t-link-container__item--blended">
                                                           shoubhikbanerjee.com
                                                        </span>
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="o-grid__col-md-3  o-grid__col-sm-6">
                                        <div class="o-content">
                                            <div class="o-content__body">
                                                <a href="#" target="_blank" class="t-link-container">
                                                    <h4>Email</h4>
                                                    <p>
                                                        <span class="t-link-container__item--blended">
                                                            banerjee.shoubhik01@gmail.com
                                                        </span>
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <DownloadAndShare />
                            <hr class="c-footer__contact-divider" />

                            <div class="o-content">
                                <div class="c-footer__bottom">
                                    <div class="c-footer__brand">

                                        <div class="c-brand">
                                            <div class="o-content__body">
                                                <h1 class="c-brand__title  t-title">
                                                    <span class="c-brand__sizer  c-brand__sizer--sm">
                                                        <span class="a-footer  c-brand__first-word  t-title__first-word">
                                                            Shoubhik
                                                            </span>
                                                        <span class="a-footer  c-brand__second-word  t-title__second-word">
                                                            Banerjee
                                                            </span>
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>

                                    </div>

                                    <ul class="c-footer__social-buttons  c-social-buttons  o-content__body">
                                        <li class="a-footer">
                                            <a href="https://wa.me/917061648322&text=Hello!" target="_blank" class="c-social-button  t-social-button">
                                                <i class="fa fa-whatsapp"></i>
                                            </a>
                                        </li>
                                        <li class="a-footer">
                                            <a href="https://www.linkedin.com/in/shoubhik-banerjee-696838109/" target="_blank" class="c-social-button  t-social-button">
                                                <i class="fa fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li class="a-footer">
                                            <a href="mailto:banerjee.shoubhik01@gmail.com" target="_blank" class="c-social-button  t-social-button">
                                                <i class="fa fa-envelope-o"></i>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

        );
    }
}

export default withRouter(Contact);