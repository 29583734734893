import React, { Component } from 'react';
import { observer, inject } from "mobx-react";
import { withRouter, NavLink } from "react-router-dom";
import RightPanelOptions from './RightPanelOptions';
let store = null;
var self = null;
@inject("appStore")
@observer
class Experience extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <section class="o-section  t-section  ">

                <div class="o-section__header-bg  t-section__header"></div>
                <div class="o-section__content-bg  t-section__content"></div>

                <div class="o-container">
                    <div class="o-section__container">

                        <header class="o-section__header  t-section__header">
                            <div class="o-content">
                                <h2 class="o-section__heading">
                                    Experience
                                </h2>
                                <div class="o-content__body  o-section__description">
                                    Yes. I&#39;ve been around.
                                </div>
                            </div>
                        </header>

                        <div class="o-section__content  t-section__content  u-pb-0">

                            <div class="a-experience-timeline  c-timeline  t-border-color">
                                <div class="c-timeline__item">
                                    <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div class="o-content">
                                        <div class="o-grid">
                                            <div class="o-grid__col-md-5">
                                                <div class="c-work__timeframe">
                                                    2023 &ndash; Present
                                                </div>
                                                <h3 class="c-work__heading">
                                                    AWS + Conversational Chatbots.
                                                </h3>
                                                <h4 class="c-work__title">
                                                    IT Expert
                                                </h4>
                                                <div class="c-work__location">
                                                    Volkswagen Group Technology Pvt. Ltd. (VWITS), Pune, India
                                                </div>
                                            </div>
                                            <div class="o-grid__col-md-7">
                                                <p>
                                                I focus on architecting and implementing advanced cloud solutions using AWS. My primary responsibilities include building AWS Landing Zone architectures, defining organizational structures, and managing Service Control Policies (SCPs) to ensure robust governance across the cloud infrastructure. Additionally, I am leading the development of AI-powered chatbots using AWS Bedrock and other AWS-native services to streamline internal and customer-facing operations.
                                                </p>
                                                <p>
                                                One of my key projects is building a frontend tool using React.js and hosting it on AWS Amplify. This tool allows users to easily create and provision AWS accounts and deploy resources within them. By combining React.js for the frontend with AWS backend services, I’ve created a seamless user experience for deploying and managing AWS resources efficiently and securely.
                                                </p>
                                                <p>
                                                In this role, I leverage my expertise in cloud computing, automation, and conversational AI to deliver scalable, secure, and intelligent solutions that support VWITS's strategic goals. I work closely with cross-functional teams to integrate these solutions seamlessly, optimizing business processes and driving innovation through automation and AI.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="c-timeline__item">
                                    <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div class="o-content">
                                        <div class="o-grid">
                                            <div class="o-grid__col-md-5">
                                                <div class="c-work__timeframe">
                                                    2021 &ndash; 2023
                                                </div>
                                                <h3 class="c-work__heading">
                                                    AWS + NLP &amp; Products.
                                                </h3>
                                                <h4 class="c-work__title">
                                                    Consultant
                                                </h4>
                                                <div class="c-work__location">
                                                    Capgemini., Kolkata, India
                                                </div>
                                            </div>
                                            <div class="o-grid__col-md-7">
                                                <p>
                                                    To develop products based on AWS Infrastructure (Lambda , CDK , Stepfunctions, SQS, SES, SNS, DynamoDB, etc.) with Node JS as backend &amp; React Js as frontend . Adding intelligence to it using various NLP bots.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="c-timeline__item">
                                    <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div class="o-content">
                                        <div class="o-grid">
                                            <div class="o-grid__col-md-5">
                                                <div class="c-work__timeframe">
                                                    2020 &ndash; 2021
                                                </div>
                                                <h3 class="c-work__heading">
                                                    NLP | NLU | NLG  -  Components &amp; Products.
                                                </h3>
                                                <h4 class="c-work__title">
                                                    Senior Software Engineer
                                                </h4>
                                                <div class="c-work__location">
                                                    Chickpea India Software Pvt. Ltd., Kolkata, India
                                                </div>
                                            </div>
                                            <div class="o-grid__col-md-7">
                                                <p>
                                                    To develop  NLP components like Text classification, summarization, Information retrieval, Open-domain question-answering bots, Recommender System, Customer support management automation, etc. using the combined knowledge of machine learning and deep learning, by finetuning various transformers, building LSTMs and other deep learning models and APIs by Python and NodeJs and deploy it to GCP.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="c-timeline__item">
                                    <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div class="o-content">
                                        <div class="o-grid">
                                            <div class="o-grid__col-md-5">
                                                <div class="c-work__timeframe">
                                                    2019 &ndash; 2020
                                                </div>
                                                <h3 class="c-work__heading">
                                                    Contextual Chatbots - Dialogflow + Node + AWS
                                                </h3>
                                                <h4 class="c-work__title">
                                                    Senior Software Engineer
                                                </h4>
                                                <div class="c-work__location">
                                                    Chickpea India Software Pvt. Ltd., Kolkata, India
                                                </div>
                                            </div>
                                            <div class="o-grid__col-md-7">
                                                <p>
                                                    To build an end-to-end contextual chatbot with Dialogflow whose main target was to manage the support automation for Hexa H2O (a cab service for office goers operating in Kolkata) and connect it with the Postgresql database, Dynamo DB, S3, backend using AWS socket. The frontend was developed in React JS and client-side socket was managed by socket io.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="c-timeline__item">
                                    <div class="c-timeline__point  t-timeline__point  t-primary-bg"></div>
                                    <div class="o-content">
                                        <div class="o-grid">
                                            <div class="o-grid__col-md-5">
                                                <div class="c-work__timeframe">
                                                    2017 &ndash; 2019
                                                </div>
                                                <h3 class="c-work__heading">
                                                    Application(s) on  NODE + Python + React - AWS
                                                </h3>
                                                <h4 class="c-work__title">
                                                    Software Engineer
                                                </h4>
                                                <div class="c-work__location">
                                                    Chickpea India Software Pvt. Ltd., Kolkata, India
                                                </div>
                                            </div>
                                            <div class="o-grid__col-md-7">
                                                <p>
                                                    To develop and maintain various applications for Hexaride Technologies Pvt. Ltd. as mentioned below :
                                                    <br></br>
                                                    <br></br>
                                                    &#10003; Customer facing Android apps in Android Studio using JAVA.
                                                    <br></br>
                                                    &#10003; Web portal to manage entire Operations in React JS, APIs in Node Js and Python.
                                                    <br></br>
                                                    &#10003; Project Route Analysis - S3 + ELK Stack + Node JS
                                                    <br></br>
                                                    &#10003; Databases used : Postgresql, DynamoDb, MongoDb, Firebase.
                                                    <br></br>
                                                    &#10003; Storage used : GCP Bucket, AWS S3.
                                                    <br></br>
                                                    &#10003; APIs : Build with Python and Node Js.
                                                    <br></br>
                                                    &#10003; Authentication + Authorizer : AWS Cognito
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </section>
        );
    }
}

export default withRouter(Experience);